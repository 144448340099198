<!-- 顶部 -->
<template>
<div class="root-241102">
    <div class="left"></div>
    <div class="title">{{ $getLang('匿名交流平台') }}</div>
    <div class="right">
        <div class="lang-container">
            <!-- <div class="label">语言</div> -->
            <el-select v-model="language" placeholder="请选择语言" class="select">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
            </el-select>
        </div>
        <div class="user-container">
            <img src="https://img1.baidu.com/it/u=3721293528,1391032071&fm=253&fmt=auto?w=171&h=171" alt="" class="img"/>
            <div class="username">我是张三</div>
        </div>
    </div>
</div>
</template>

<script>
import{
    ElSelect,
    ElOption
} from 'element-plus'
import {
    langOption
} from "./data"
export default {
    name: "TopContainer",
    components: {
        ElSelect,
        ElOption
    },
    data() {
        return{
            language: 'zh-CN',
            options: langOption,    // 语言选项
        }
    },
    watch: {
        language(newValue) {
            this.$store.commit("setLanguage", newValue);
            document.title = this.$getLang("匿名交流平台");
        }
    }
}
</script>

<style scoped>
.root-241102 {
    background: #efebeb52;
    border-bottom: none;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .left {
        flex-grow: 1;
    }

    .right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 13px;

        .lang-container {
            display: flex;
            align-items: center;
            .select {
                min-width: 100px;
            }
        }
        .user-container {
            border-radius: 8px;
            right: 145px;
            display: flex;
            align-items: center;
            margin-right: 7vw;
            .img {
                width: 30px;
                height: 30px;
                display: bolck;
                vertical-align: bottom;
            }
            .username {
                margin-left: 10px;
            }
        }
    }
}
</style>