
// 语言选项
export const langOption = [
    {
        value: 'zh-CN',
        label: '简体中文'
    },
    {
        value: 'en',
        label: 'English'
    }
]