<!-- 主要区域 -->
<template>
<div class="root-class2411034">
    <router-view />
</div>
</template>

<script>
export default {
    name: 'MainContainer'
}
</script>

<style scoped>
.root-class2411034 {
    flex-grow: 1;
    border: 1px solid #d8d3d3;
    overflow: hidden;
    background: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
</style>