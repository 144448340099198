import { createStore } from 'vuex'

export default createStore({
  state: {
    refreshAppValue: 1,    // 修改该值，可以刷新App.vue
    language: 'zh-CN',        // 系统使用哪种语言
  },
  getters: {
  },
  mutations: {
    refreshApp(state) {
      state.refreshAppValue++;
    },
    setLanguage(state, lang) {
      state.language = lang;
    }
  },
  actions: {
  },
  modules: {
  }
})
