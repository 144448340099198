/**
 * 一些关于注册登录、权限校验的方法
 */

import { request } from "@/utils/request";
import store from '../store'
import {
    myEncrypt
} from '@/utils/crypto'

/**
 * 入口方法
 * 获取accessToken字段并保存
 */
export async function getAccessToken() {
    console.log("getAccessToken");
    // 检查是否已经注册
    let uuid = localStorage.getItem("uuid");
    if(uuid == null) {  // 还没注册
        // 注册
        await register().then(res => {
            console.log("res", res);
            if(res.code === 1000) {
                localStorage.setItem("uuid", res.result);
                uuid = res.result;
            } else {
                console.error("无法获取uuid");
            }
        }).catch(error => {
            console.error(error);
        })
    } else {   // 已注册
        console.log("已注册");
    }

    // 接下来进行登录
    if(uuid != null) {
        console.log("进行登录");
        login({
            uuid: myEncrypt(uuid)
        }).then(res => {
            console.log(res);
            if(res.code === 1000) {
                localStorage.setItem("accessToken", res.result.accessToken);
                if(res.result.uuid) {
                    localStorage.setItem("uuid", res.result.uuid);
                }
                // 刷新页面，以便重新请求数据
                store.commit('refreshApp');
                // setTimeout(() => {
                //     localStorage.setItem("accessToken", res.result);
                // }, 2000);
                
            } else {
                // 清空数据，刷新界面
                clearAllData();
                // window.location.reload();
            }
        })
    }

}

/**
 * 注册，获取一个uuid作为用户标识符
 * @returns 
 */
function register() {
    return request("/user/register", {
        method: "POST"
    });
}

function login(data) {
    return request("/user/login", {
        method: "POST",
        body: JSON.stringify(data)
    })
}

/**
 * 清空所有数据
 */
function clearAllData() {
    localStorage.clear();
    sessionStorage.clear();
}