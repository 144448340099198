// 基础性全局变量，如baseUrl

const env = {
  // 公共属性
  common: {
    baseUrl: 'http://120.76.239.116/api'
    // baseUrl: 'http://127.0.0.1:8080/api'
  },

  // 开发环境变量
  development: {
    ENV: 'development'
  },

  // 正式环境变量
  production: {
    ENV: 'production'
  }
}

const environment = {
  ...env.common,
  ...env[process.env.NODE_ENV]
}

export default environment;
