import { ElSelect, ElOption } from 'element-plus';
import 'element-plus/es/components/select/style/css'
import 'element-plus/es/components/option/style/css'

import { langOption } from "./data";
export default {
  name: "TopContainer",
  components: {
    ElSelect,
    ElOption
  },
  data() {
    return {
      language: 'zh-CN',
      options: langOption // 语言选项
    };
  },
  watch: {
    language(newValue) {
      this.$store.commit("setLanguage", newValue);
      document.title = this.$getLang("匿名交流平台");
    }
  }
};