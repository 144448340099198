<template>
  <template v-if="!isMyPage">
    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav>
    <button @click="enterMyPage">进入我的界面</button>
    <router-view/>
  </template>

  <div v-else class="root-class2411021" :refreshApp="refreshAppValue">
    <TopContainer></TopContainer>
    <div style="background:white; height: 15px;">&nbsp;</div>
    <div class="bottom-contianer">
      <LeftContainer></LeftContainer>
      <MainContainer></MainContainer>
    </div>
  </div>
  
</template>

<script>
import TopContainer from "@/layout/TopContainer.vue";
import LeftContainer from "@/layout/LeftSide.vue";
import MainContainer from "@/layout/MainContainer.vue"
import { mapState } from "vuex";
export default{
  components: {
    TopContainer,
    LeftContainer,
    MainContainer
  },
  data() {
    return {
      isMyPage: true
    }
  },
  mounted() {
    document.title = this.$getLang("匿名交流平台");
  },
  computed: mapState([
    'refreshAppValue'
  ]),
  methods: {
    enterMyPage() {
      this.isMyPage = true;
    }
  }
}
</script>

<style scoped>
.root-class2411021 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 900px;
  background: #efebeb52;
  overflow-x: auto;
  .bottom-contianer {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    padding: 15px;
    padding-top: 0;
  }
}
</style>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
