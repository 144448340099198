import environment from "@/config/env"

export function request(url, options) {
  let accessToken = localStorage.getItem("accessToken");
  
  // 选项的默认配置
  const defaults = {
    headers: {
      'Content-Type': 'application/json',
      "accessToken": accessToken? accessToken : ''
    }
  }

  // 合并选项
  const opts = {
    ...defaults,
    ...options
  }

  // 拼接url
  const fullUrl = environment.baseUrl + url;
  // console.log("fullUrl", fullUrl);

  return fetch(fullUrl, opts).then(response => {
    // console.log('response', response);

    return response.json();
  }).catch(error => {
    console.error(error);
  })

}