// 存放一些跟多语言功能相关的方法
import store from '../store'
import {
    lang,
    keyObj
} from "@/static/language"

export function getLang(zhStr) {
    const language = store.state.language;
    // 获取zhStr的key
    const key = keyObj[zhStr];
    const obj = lang[key];
    if(!obj) {
        return zhStr;
    } else {
        return obj[language];
    }
    
}