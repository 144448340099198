<!-- 侧边栏 -->
<template>
<div class="root-class2411033">
    <div v-for="(item, index) in menuArr" 
        class="menu-item" :key="index" 
        :class="{'active':currentMenuIndex==index}"
        @click="onClickItem(item, index)"
    >
        {{ this.$getLang(item.text) }}
    </div>
</div>
</template>

<script>
export default {
    name: "LeftSide",
    data() {
        return {
            menuArr: [
                {
                    text: "Hello!",
                    route: '/'
                },
                {
                    text: '公共聊天',
                    route: '/publicChat'
                }
            ],
            currentMenuIndex: 0
        }
    },
    created() {
        // console.log("LeftSide created");
        this.setMenuIndexByUrl();
    },
    methods: {
        onClickItem(item, index) {
            this.currentMenuIndex = index;
            this.$router.push(item.route);
        },
        // 根据url来设置菜单选中状态
        setMenuIndexByUrl() {
            const arr = this.menuArr;
            
            // 优先根据window.location.pathname来判断
            const urlPath = window.location.pathname;
            let flag = 0; // 是否找到正确的菜单状态了
            for(let i = 0; i < arr.length; i++) {
                if(arr[i].route == urlPath) {
                    this.currentMenuIndex = i;
                    flag = 1;
                    break;
                }
            }
            if(flag) return;

            // 其次根据this.$route.path来判断
            for(let i = 0; i < arr.length; i++) {
                if(arr[i].route == this.$route.path) {
                    this.currentMenuIndex = i;
                    flag = 1;
                    break;
                }
            }

        }
    }
}
</script>

<style scoped>
.root-class2411033 {
    width: 150px;
    border: 1px solid #d8d3d3;
    border-right: none;
    padding: 30px 15px;
    background: white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    .menu-item {
        border: 1px solid #d8d3d3;
        border-radius: 5px;
        padding: 7px;
        margin-top: 8px;
    }
    .menu-item:hover{
        cursor: pointer;
    }
    .active {
        background: rgb(245, 245, 245);
        color: rgb(75, 158, 95);
        border-color: rgb(75, 158, 95);
    }
}
</style>