/**
 * 存放一些加密解密相关的工具方法
 *
 */
// const forge = require('node-forge');

/**
 * 自己实现的加密解密函数
 * 加密思路：将字符串逐个字符转换成二进制，按位取反，再转换成十六进制字符
 * 解密思路：将密文字符串逐个字符转换成二进制，按位取反，最后合并
 */

// 加密
export function myEncrypt(str) {
    if(str === undefined || str === null) console.error("参数类型错误");
    if(typeof(str) != 'string') console.error("参数类型错误");
    
    let hexString = '';

    // 遍历每个字符
    for (let i = 0; i < str.length; i++) {
        // 获取字符的 Unicode 编码并转换为 8 位二进制字符串
        let bin = str.charCodeAt(i).toString(2).padStart(8, '0');

        // 按位取反
        let invertedBin = bin.split('').map(bit => bit === '0' ? '1' : '0').join('');
        
        // 将取反后的二进制转换为十六进制
        let invertedHex = parseInt(invertedBin, 2).toString(16).padStart(2, '0');
        
        // 累加到最终结果
        hexString += invertedHex;
    }

    return hexString;

}

// 解密
export function myDecrypt(hexStr) {
    let str = hexStr;
    if(str === undefined || str === null) console.error("参数类型错误");
    if(typeof(str) != 'string') console.error("参数类型错误");
    
    let originalString = '';

    // 按字节处理十六进制字符串
    for (let i = 0; i < hexStr.length; i += 2) {
        // 取出两个十六进制字符，转换为十进制整数
        let hexByte = hexStr.slice(i, i + 2);
        let decimal = parseInt(hexByte, 16);

        // 将十进制数转换为二进制
        let bin = decimal.toString(2).padStart(8, '0');

        // 按位取反
        let invertedBin = bin.split('').map(bit => bit === '0' ? '1' : '0').join('');

        // 将取反后的二进制转换为十进制
        let invertedDecimal = parseInt(invertedBin, 2);

        // 将十进制值转换为字符
        let char = String.fromCharCode(invertedDecimal);

        // 将字符添加到结果中
        originalString += char;
    }

    return originalString;

}

// 测试我的加密方法
export function test() {
    const mingwen = 'a12!_';
    let miwen = myEncrypt(mingwen);
    console.log("明文", mingwen);
    console.log("密文", miwen);

    console.log("解密后", myDecrypt(miwen));
}

//SHA-256映射-start
// 使用 Web Crypto API 计算 SHA-256 哈希值
export async function calculateSHA256(input) {
    // 将输入转换为字节数组
    const encoder = new TextEncoder();
    const data = encoder.encode(input);

    // 使用 SHA-256 算法进行哈希计算
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    // 将 ArrayBuffer 转换为十六进制字符串
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // 将 ArrayBuffer 转换为字节数组
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

    return hashHex;
}
//SHA-256映射-end


// // AES算法相关配置-start

// // 秘钥
// const key = '1234567890abcdef'
// // 偏移量 16位
// const iv = 'abcdef9876543210'
// // AES算法相关配置-end
// // 加密-AES
// function encrypt(someBytes) {
//     var cipher = forge.cipher.createCipher('AES-GCM', key); // 生成AES-GCM模式的cipher对象 并传入密钥
//     cipher.start({
//         iv: iv,
//         additionalData: 'nvn', // optional
//         tagLength: 128 // optional, defaults to 128 bits
//     });
//     cipher.update(forge.util.createBuffer(forge.util.encodeUtf8(someBytes)));
//     cipher.finish();
//     var encrypted = cipher.output;
//     var tag = cipher.mode.tag;
//     return btoa(encrypted.data+tag.data)
// }

// // 解密-AES
// function decrypt(someBytes) {
//     someBytes = atob(someBytes)
//     // const iv = someBytes.slice(0, 12)
//     const tag = someBytes.slice(-16)
//     const data = someBytes.slice(0, someBytes.length - 16)
//     var decipher = forge.cipher.createDecipher('AES-GCM', key)
//     decipher.start({
//         iv: iv,
//         tag: tag,
//         additionalData: 'nvn', // optional
//         tagLength: 128 // optional, defaults to 128 bits
//     });
//     decipher.update(forge.util.createBuffer(data))
//     const pass = decipher.finish()
//     if (pass) {
//       return decipher.output.toString()
//     }
// }

// // 用于测试AES算法是否可用
// export function testAES() {
//     console.log("AES测试开始");
//     const mingwen = "Hello!世界！@";
//     const miwen = encrypt(mingwen);
//     console.log("加密后", miwen);

//     try {
//         const data = decrypt(miwen);
//         console.log("解密后", data);
//     } catch(e) {
//         console.error(e);
//     }
    
//     console.log("AES测试结束");

// }